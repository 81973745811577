body {
  background-color: #e9ecef !important;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Navbar */
.custom-navbar {
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #e9ecef;
}

.navbar-placeholder {
  height: 92px; /* height equal to navbar height */
}

.nav {
  padding-right: 0 !important;
}

.navbar-text a {
  padding: 8px;
}

.nav-item a {
  padding: 4px 16px;
  text-transform: uppercase;
  font-size: 18px;
}

.navbar-nav > a {
  text-transform: uppercase;
  font-size: 18px;
}

div.dropdown-menu.show {
  background-color: #e9ecef;
}

.custom-navbar .active.nav-item a {
  color: #000000E6;
}

.custom-navbar .dropdown-item.active {
  background-color: #abadae;
}

.custom-navbar .dropdown-item:hover {
  background-color: #abadae;
}

.navbar-text > a > svg > path:hover {
  color: rgba(0, 0, 0, 0.7);
}

/* section Drafts */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: space-between;
}

/* PhotoOverlay */
.photo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  color: #bbb;
  font-size: 42px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover {
  color: #fff;
}

/* Hero-image */
.hero-wrapper {
  position: relative;
  height: auto;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  /* opacity: 0.8; */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

@media (max-width: 767px) {
  .hero-wrapper {
    display: none;
  }
  .hero-image {
    display: none;
  }
  .custom-navbar {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .custom-navbar span.navbar-text {
    display: none;
  }
  .mb-4.row {
    margin-left: 12px !important;
    margin-right: 12px !important;    
  }
  div.container-about {
    padding-top: 0 !important;
  }
  .container-about > * {
    margin-left: 12px !important;
    margin-right: 12px !important;    
  }
  .container-contact > * {
    margin-left: 12px !important;
    margin-right: 12px !important;    
  }
  .navbar-brand {
    font-size: 36px !important;
  }
}
